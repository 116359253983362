html {
  font-family: Montserrat, Ariel;
}

body {
  background-color: #C1C6C8;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  box-sizing:border-box;
  border: inset 0px red;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}